<template lang="pug">
.wrapper(v-if='course', data-view)
  bread-crumb(
    :parentRoute='{ label: "Catalogue", path: { name: "Courses" } }',
    :currentRoute='{ label: course.data.title[0].text }'
  )
  main
    section.course-meta
      template(v-if='course.data.video.embed_url')
        prismic-rich-text(:field='course.data.video_intro_text')
        prismic-embed.course-meta__video(:field='course.data.video')
      prismic-image.course-meta__image(v-else, :field='course.data.image') 
      .course-meta__cta-card
        h3 Votre programme et vous
        ul.course-meta__cta-card__profits
          li.course-meta__cta-card__profits__profit(v-for='profit in course.data.programme_and_you') 
            //- <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
            //-   <path d="M12.9177 1.85205L11.3104 0.0923193C11.2564 0.0332163 11.183 0 11.1066 0C11.0301 0 10.9568 0.0332163 10.9028 0.0923193L4.11416 7.50834C4.06015 7.56745 3.98683 7.60066 3.91036 7.60066C3.83389 7.60066 3.76057 7.56745 3.70656 7.50834L2.09922 5.74861C2.04522 5.68951 1.97189 5.65629 1.89542 5.65629C1.81895 5.65629 1.74563 5.68951 1.69162 5.74861L0.0842782 7.50834C-0.0280927 7.63105 -0.0280927 7.82997 0.0842782 7.95268L3.70656 11.9077C3.76057 11.9668 3.83389 12 3.91036 12C3.98683 12 4.06015 11.9668 4.11416 11.9077L12.9177 2.29199C13.0274 2.1698 13.0274 1.97424 12.9177 1.85205Z" fill="#001A49" fill-opacity="0.6"/>
            //- </svg>
            | {{ profit.text }}
        common-button(
          v-if='course && courseFreeVideosSlice(course)',
          label="Je reçois 3 vidéos gratuites",
          variant='green',
          fullWidth,
          :path='{ name: "FreeTrial", props: { course } }'
        )
        common-button.mt-2(
          v-if='course.data.active && course.data.button_active',
          :label='buttonText',
          variant='orange',
          fullWidth,
          withShadow,
          @click='addCourseToCard',
          isButton
        )
        common-button.mt-2(
          v-if='course.data.active && course.data.button_active',
          label='J\'ai déjà ce programme',
          variant='primary',
          fullWidth,
          withShadow,
          isButton,
          v-b-modal.tuto_modal
        )
        b-modal#tuto_modal(centered, ok-only, ok-title='Compris')
          p Retrouvez votre cahier et vos vidéos dans votre espace client systeme.io : {{ " " }}
            a(href='https://systeme.io/dashboard/fr/login', target='_blank') Accédez à votre espace
    section.course-body
      prismic-rich-text.course-body__title(:field='course.data.title')
      template(v-if='course.data.active')
        section.course-body__description
          prismic-rich-text(v-if='course.data.description_title[0]', :field='course.data.description_title')
          h3(v-else) Philosophie du programme
          prismic-rich-text(:field='course.data.description')
        section.course-body__flow
          prismic-rich-text(v-if='course.data.flow_title[0]', :field='course.data.flow_title')
          h3(v-else) Deroulement
          prismic-rich-text(:field='course.data.flow')
        ul.course-body__tags
          li.course-body__tags__tag(v-for='tag in course.data.tags')
            <svg width="13" height="12" viewBox="0 0 13 12" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.9177 1.85205L11.3104 0.0923193C11.2564 0.0332163 11.183 0 11.1066 0C11.0301 0 10.9568 0.0332163 10.9028 0.0923193L4.11416 7.50834C4.06015 7.56745 3.98683 7.60066 3.91036 7.60066C3.83389 7.60066 3.76057 7.56745 3.70656 7.50834L2.09922 5.74861C2.04522 5.68951 1.97189 5.65629 1.89542 5.65629C1.81895 5.65629 1.74563 5.68951 1.69162 5.74861L0.0842782 7.50834C-0.0280927 7.63105 -0.0280927 7.82997 0.0842782 7.95268L3.70656 11.9077C3.76057 11.9668 3.83389 12 3.91036 12C3.98683 12 4.06015 11.9668 4.11416 11.9077L12.9177 2.29199C13.0274 2.1698 13.0274 1.97424 12.9177 1.85205Z" fill="#001A49" fill-opacity="0.6"/>
            </svg>
            | {{ tag.text }}
      template(v-else)
        section.course-wip
          p.course-wip__text Votre programme est en cours de tournage
          img.course-wip__image(src='@/assets/illustration_tournage.jpg')
          span.course-wip__release-date(v-if='course.data.release_date') Date de sortie : {{ course.data.release_date | formatDate }}
  free-trial-modal(v-if='course && courseFreeVideosSlice(course)', ref='freeTrialModal', :passedCourse='course')
</template>

<script>
import { formatDate } from '@/filters/dateFormatter.js'
import { BModal, VBModal } from 'bootstrap-vue'

export default {
  name: 'CourseItem',
  filters: { formatDate },
  components: {
    CommonButton: () => import('@/components/CommonButton'),
    BreadCrumb: () => import('@/components/BreadCrumb/index'),
    BModal,
    FreeTrialModal: () => import('@/components/FreeTrialModal')
  },
  directives: { 'b-modal': VBModal },
  metaInfo() {
    return {
      title: this.course && this.course.data.title1,
      meta: [
        {
          vmid: 'description',
          name: 'description',
          content: this.course && this.course.data.description
        }
      ]
    }
  },
  data() {
    return {
      course: null
    }
  },
  computed: {
    buttonText() {
      return `Je passe à l'action (${this.course.data.price[0].text})`
    }
  },
  created() {
    this.getCourseByUid()
  },
  methods: {
    async getCourseByUid() {
      const result = await this.$prismic.client.getByUID('course', this.$route.params.uid)
      this.course = result
    },
    addCourseToCard() {
      this.$store.commit('ADD_ITEM_TO_CARD', this.course)
    },
    openAccessTuto() {
      this.$refs.modal.open()
    },
    showFreeTrialModal() {
      this.$refs.freeTrialModal.show()
    },
    courseFreeVideosSlice(course) {
      return course.data.body.find((slice) => slice.slice_type === 'free_videos')
    }
  }
}
</script>

<style lang="scss" scoped>
@import '@/styles/mixins.scss';
@import '@/styles/colors.scss';

main {
  position: relative;

  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;

  row-gap: 20px;

  @include medium-screen {
    justify-content: space-between;
  }

  &::v-deep {
    h3 {
      font-family: 'Bebas Neue', sans-serif;
      font-weight: normal;
      font-size: 1.2rem;
      margin-bottom: 1rem;
      color: $lightBlue;

      @include medium-screen {
        font-size: 1.5rem;
      }
      @include large-screen {
        font-size: 1.8rem;
      }
    }
  }

  .course-meta {
    // min-width: 300px;
    max-width: 100vw;
    flex-basis: 100%;
    margin-bottom: 1rem;

    @include medium-screen {
      flex-basis: 35%;
    }
    @include large-screen {
      flex-basis: 25%;
    }

    &__image {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 8px 12px 0px rgba(150, 150, 150, 0.4);
      margin-bottom: 1.5rem;
    }

    &__video {
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 8px 12px 0px rgba(150, 150, 150, 0.4);
      margin-bottom: 1.5rem;
      min-height: 200px;
      overflow: hidden;

      &::v-deep {
        iframe {
          width: 100%;
          min-height: 200px;
        }
      }
    }

    &__cta-card {
      min-height: 200px;
      width: 100%;
      border-radius: 10px;
      box-shadow: 0px 8px 12px 0px rgba(150, 150, 150, 0.4);
      padding: 1rem;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      &__profits {
        font-family: 'Libre Franklin', sans-serif;
        // margin-left: 1rem;
        // list-style-image: url('../assets/feu.svg');

        &__profit {
          margin-bottom: 1rem;
          display: flex;
          &::before {
            content: '';
            display: inline-block;
            height: 20px;
            width: 20px;
            background-image: url('../assets/feu.svg');
            margin-right: 0.5rem;
            margin-top: -0.2rem;
          }

          svg {
            margin-right: 0.5rem;
          }
        }
      }
    }
  }

  .course-body {
    flex-basis: 70%;
    flex-grow: 1;

    @include medium-screen {
      flex-grow: 0;
      flex-basis: 60%;
    }
    @include large-screen {
      flex-basis: 70%;
    }

    &__title {
      margin-bottom: 1.5rem;
      &::v-deep {
        h1 {
          font-size: 1.5rem;
          font-family: 'Bebas Neue', sans-serif;
          color: $lightBlue;
          font-weight: normal;
          color: $darkBlue;

          @include medium-screen {
            font-size: 1.8rem;
          }
          @include large-screen {
            font-size: 2.2rem;
          }
        }
      }
    }

    &__description {
      margin-bottom: 3rem;
    }
    &__flow {
      margin-bottom: 3rem;
    }

    &__tags {
      font-family: 'Libre Franklin', sans-serif;
      display: flex;
      flex-wrap: wrap;

      padding-left: 0 !important;

      &__tag {
        margin-right: 2rem;
        margin-bottom: 1rem;
        list-style: none;

        svg {
          margin-right: 0.5rem;
        }
      }
    }

    .course-wip {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-around;

      &__text {
        font-size: 1.6rem;
        font-weight: 600;
        font-family: 'Hind Vadodara', sans-serif;
        text-align: center;
      }

      &__image {
        max-width: 100%;
        margin-bottom: 2rem;
      }

      &__release-date {
        font-family: 'Hind Vadodara', sans-serif;
        font-size: 1.6rem;
        font-weight: 600;
      }
    }

    ::v-deep {
      ul {
        list-style: initial;
        padding-left: 1rem;
        font-family: 'Libre Franklin', sans-serif;
        line-height: 1.8;
      }
    }
  }
}
</style>
